<template>
    <div
        class="child-container"
    >
        <div
            class="child-details"
        >
            born on {{child.day}}/{{child.month}}/{{child.year}}
        </div>

        <button
            class="remove"
            @click.prevent="$emit('remove')"
        >
            x
        </button>

        <input
            v-model="child.day"
            type="hidden"
            class="child-day"
            :name="name('day')"
        />
        <input
            v-model="child.month"
            type="hidden"
            class="child-month"
            :name="name('month')"
        />
        <input
            v-model="child.year"
            type="hidden"
            class="child-year"
            :name="name('year')"
        />

        <div
            v-if="error"
            class="text-red"
        >
            {{page.form_child_error}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        child: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        error: {
            type: Boolean,
            required: true,
        },
        page: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {};
    },

    methods: {
        name(key) {
            return `children[${this.index}][${key}]`;
        },
    },
};
</script>
