import key from './keycodes';

function handleFirstTab({ keyCode }) {
    if (keyCode === key.tab) {
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);
const local = (`${location.protocol}//${location.host}`);

$(`a:not([href^='${local}'])`).attr("target","_blank");
$(`a[href^='${local}']`).attr("target","_self");
$(`a[href^='/']`).attr("target","_self");
