import $ from 'jquery';

const $navBar = $('header');
const $html = $('html');
const $toggleNav = $('#nav-toggle');
const $logo = $('.brand svg');
const $tier1Menu = $('nav ul');
const $tier2Menu = $('nav ul li a:not(:only-child)');
const $tier2MegaMenu = $('nav ul li.has-megamenu a:not(:only-child)');
const $navDropDown = $('.nav-dropdown');
const $navMega = $('.has-megamenu > .nav-mega');
const scrolledClass = 'scrolled';
const notScrolledClass = 'not-scroll';

$($tier2Menu).click(function toggleSubnav(e) {
    $(this).siblings($navDropDown).toggle();
    $($navDropDown).not($(this).siblings()).hide();
    e.stopPropagation();
});

$($tier2MegaMenu).click(function toggleSubnav(e) {
    console.log($(this).hasClass('enable_close'));
    if(!$(this).hasClass('enable_close')){
        e.stopPropagation();
        if ($('body').hasClass('transparent')) {
            $($navBar).removeClass('transparent').addClass('noTransparent');
        }
        $($navBar).addClass('header-megamenu');
        $('.active-megamenu').removeClass('active-megamenu');
        $('.has-megamenu > .has-submenu').removeClass('active-submega');
        $(this).parent('.has-megamenu').addClass('active-megamenu');
        $(this).addClass('active-submega').siblings($navMega).css('display', 'flex');



        $($navMega).not($(this).siblings()).hide();
        const beforeHeight = ($($navBar).outerHeight() + $('.active-megamenu > .nav-mega').outerHeight(true));
        $(`<style>.header-megamenu:before {height: ${beforeHeight}px;}</style>`).appendTo('head');

        if($(window).width() < 768){
            $(this).addClass('enable_close');
        }
    }else{
        $('.enable_close').removeClass('enable_close');

        if ($($navMega).is(':visible')) {
            if ($('body').hasClass('transparent')) {
                $($navBar).removeClass('noTransparent').addClass('transparent');
            }
            $('.active-megamenu').removeClass('active-megamenu');
            $($navBar).removeClass('header-megamenu');
            $($navMega).hide();
            $($tier2MegaMenu).removeClass('active-submega');
        }
        if ($($navDropDown).is(':visible')) {
            $($navDropDown).hide();
        }
    }
});


$($html).click(() => {
    if ($($navMega).is(':visible')) {
        if ($('body').hasClass('transparent')) {
            $($navBar).removeClass('noTransparent').addClass('transparent');
        }
        $('.active-megamenu').removeClass('active-megamenu');
        $($navBar).removeClass('header-megamenu');
        $($navMega).hide();
        $($tier2MegaMenu).removeClass('active-submega');
    }
    if ($($navDropDown).is(':visible')) {
        $($navDropDown).hide();
    }
});

$($toggleNav).click(() => $($tier1Menu).slideToggle());

$($toggleNav).on('click', function toggleNavClass() {
    this.classList.toggle('active');
});

$(window).bind('scroll', () => {
    const scrollOffset = $(window).scrollTop();

    if (scrollOffset > 100) {
        $navBar.addClass(scrolledClass);
        $($logo).removeClass(notScrolledClass);
    } else {
        $navBar.removeClass(scrolledClass);
        $($logo).addClass(notScrolledClass);
    }
});

const initialscrollOffset = $(window).scrollTop();

if (initialscrollOffset > 0) {
    $navBar.addClass(scrolledClass);
    $($logo).removeClass(notScrolledClass);
}

/* eslint-disable */
$('.has-submenu').on('click', function (e) {
    e.preventDefault();
});

if($navMega.length){
    let navmega_height = $($navMega).outerHeight(true);

    var beforeHeight = ($($navBar).outerHeight() + navmega_height);

    $('<style>.header-megamenu:before {height: ' + beforeHeight + 'px;}</style>').appendTo('head');
}