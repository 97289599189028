<template>
    <div class="text-left">
        <faq
            v-for="(faq, index) in faqs"
            :key="index"
            :question="faq.question"
            :answer="faq.answer_html"
            :is-first="index === 0"
            @faq-opened="$emit('global::faq-opened', $event)"
        />
    </div>
</template>

<script>
import Faq from './Faq';

export default {
    components: { Faq },

    props: {
        faqs: {
            type: Array,
            required: true,
        },
    },
};
</script>
