<template>
    <div>
        <div
            class="dob-container"
        >
            <label
                class=""
            >
                {{page.form_add_child_dob}}
            </label>
            <input
                v-model="day"
                type="text"
                :placeholder="page.form_placeholder_add_child_dob_day"
            />
            <input
                v-model="month"
                type="text"
                :placeholder="page.form_placeholder_add_child_dob_month"
            />
            <input
                v-model="year"
                type="text"
                :placeholder="page.form_placeholder_add_child_dob_year"
            />
        </div>

        <button
            class="c-form__submit full-button"
            @click.prevent="submit"
        >
            {{page.form_button_add_child}}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        genders: {
            type: String,
            required: true,
        },
        page: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            day: '',
            month: '',
            year: '',
        };
    },

    computed: {
        child() {
            const {
                day,
                month,
                year,
            } = this;

            return {
                day,
                month,
                year,
            };
        },
        hasData() {
            return this.day && this.month && this.year;
        },
    },

    methods: {
        submit() {
            if (!this.hasData) {
                // @todo - validate properly!
                // eslint-disable-next-line no-alert
                alert('Enter All the Data');

                return;
            }

            this.$emit('add', this.child);
        },
    },
};
</script>
