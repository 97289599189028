import axios from 'axios';
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios = axios;

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    throw new Error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

function changeSelectedButton(buttonId) {
    const buttons = document.querySelectorAll('.region');
    buttons.forEach(function(button) {
        if (button.classList.contains('selected')) {
            button.classList.remove('selected');
        }
        button.classList.add('unselected');
    });

    const selectedButton = document.getElementById(buttonId);
    selectedButton.classList.remove('unselected');
    selectedButton.classList.add('selected');
}

window.changeSelectedButton = changeSelectedButton;

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

function isMobile() {
    var screenWidth = window.innerWidth;
    return screenWidth < 1200;
}

window.isMobile = isMobile;

function trimChar(string, charToRemove) {
    while(string.charAt(0) === charToRemove) {
        string = string.substring(1);
    }

    while(string.charAt(string.length-1) === charToRemove) {
        string = string.substring(0,string.length-1);
    }

    return string;
}

window.trimChar = trimChar;

(function (w) {

    w.URLSearchParams = w.URLSearchParams || function (searchString) {
        var self = this;
        self.searchString = searchString;
        self.get = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
            if (results == null) {
                return null;
            }
            else {
                return decodeURI(results[1]) || 0;
            }
        };
    }

})(window);

$(document).ready(function(){
    $("a").each(function() {
        if ($(this).attr("rel") ) {
            let currentRel = $(this).attr("rel");
            $(this).attr("rel", currentRel.replace("nofollow", "").trim());
        }
    });

    document.querySelector('textarea')?.addEventListener('keydown', function(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
        }
    });
    let screenWith = $(document).width();
    if(screenWith<991){
        $('.fancybox-class').attr('data-fancy-box','');
        $('.image-zoomable').attr('style','display:none;');
        $('.text-desktop-zoomable').addClass('hidden');
    }else{
        $('.image-zoomable-mobile').attr('style','display:none;');
        $('.text-mobile-zoomable').addClass('hidden');
    }
})
