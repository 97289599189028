import $ from 'jquery';

const ctaLink = document.getElementById('batck-to-top');

if(ctaLink !== null && ctaLink !== undefined){
    ctaLink.addEventListener('click', (e) => {
        e.preventDefault();

        $('html, body').css({
            transition: 'all 1s cubic-bezier(0,0,0,1)',
        }).animate({
            scrollTop: 0,
        });
    });
}

$(function() {
    if(isMobile()){
        $("#batck-to-top").hide();

        $(window).on('scroll touchmove', function(e){
            let scrollY = window.scrollY;
            let w_height= $(window).height();

            if(scrollY >= (w_height/2)){
                $("#batck-to-top").show();
            }else{
                $("#batck-to-top").hide();
            }
        })
    }
});
