import Vue from 'vue';
import AddChildForm from '@components/article/AddChildForm';
import ChildRow from '@components/article/ChildRow';
import cloneDeep from 'lodash/cloneDeep';

const app = new Vue({
    el: '[newsletter-article-form]',

    components: { AddChildForm, ChildRow },

    data: {
        is_expecting: window.waterwipes.input.is_expecting,
        has_children: window.waterwipes.input.has_children,
        show_add_child_form: true,
        children: window.waterwipes.input.children ?
            cloneDeep(window.waterwipes.input.children) : [],
        hasErrors: cloneDeep(window.waterwipes.hasErrors),
        errors: cloneDeep(window.waterwipes.errors),
        errorKeys: (window.waterwipes.errors),
        page: cloneDeep(window.waterwipes.page),
    },

    methods: {
        addChild(child) {
            this.children.push(child);
            this.show_add_child_form = false;
        },

        removeChild(index) {
            this.$delete(this.children, index);
        },

        childHasError(index) {
            return !!this
                .errorKeys
                .reduce((memo, item) => (item.startsWith(`children.${index}.`) || memo), false);
        },
    },
});

export default app;
