<template>
    <div
        class="c-accordion__wrapper"
        :class="{ 'border-t': isFirst, 'active': isOpen }"
    >
        <div
            class="c-accordion__wrapper--heading"
            @click="handleClick"
        >
            {{question}}
        </div>

        <div
            v-show="isOpen"
            class="c-accordion__wrapper--panel"
            v-html="answer"
        />
    </div>
</template>

<script>
/* eslint vue/no-v-html: 0 */
export default {
    props: {
        question: {
            type: String,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
        isFirst: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return { isOpen: false };
    },

    mounted() {
        this.$parent.$on('global::faq-opened', (faq) => {
            // closes if another faq opens
            if (faq !== this) {
                this.isOpen = false;
            }
        });
    },

    methods: {
        handleClick() {
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                this.$emit('faq-opened', this);
            }
        },
    },
};
</script>
