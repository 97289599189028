import axios from 'axios';

var dependenciesFields = {};
var multipleFields = {};

$.fn.serializeControls = function() {
    var data = {};

    function buildInputObject(arr, val, name) {
        if (arr.length < 1) return val;

        var objkey = arr[0];

        if (objkey.slice(-1) == "]") {
            objkey = objkey.slice(0, -1);
        }

        var isNum = !isNaN(parseInt(objkey, 10));

        if (isNum) objkey = parseInt(objkey, 10);

        var result = isNum ? [] : {};

        if (arr.length == 1) {
            var $input = $('[name="' + name + '"]');
            var isRequired = $input.attr('required');
            var type = $input.attr('type');
            var validation = '';
            if (type === 'date') {
                validation += 'date|';
            } else if (type === 'email') {
                validation += 'email|';
            }
            if (isRequired) {
                validation += 'required|';
            }

            result[objkey] = { value: val, validation: trimChar(validation, '|') };
        } else {
            arr.shift();

            var nestedVal = buildInputObject(arr, val, name);

            result[objkey] = nestedVal;
        }

        return result;
    }

    $.each(this.serializeArray(), function() {
        var val = this.value;
        var c = this.name.split("[");
        var a = buildInputObject(c, val, this.name);
        $.extend(true, data, a);
    });

    return data;
}

function formatData(input, checkboxes) {
    var data = {};
    var $input = $(input);
    var isRequired = $input.attr('required');
    var type = $input.attr('type');
    var validation = '';
    if (type === 'date') {
        validation += 'date|';
    } else if (type === 'email') {
        validation += 'email|';
    }
    if (isRequired) {
        validation += 'required|';
    }

    var value;
    if (type === 'radio') {
        var radioGroup = $input.attr('name');
        value = $("input[name='" + radioGroup + "']:checked").val();
        if (Object.prototype.toString.call(value) === "[object String]") {
            value = value.trim();
        }

        if (value == undefined) {
            value = ''
        }
    } else if (type === 'checkbox') {
        let checkBoxName = $input.attr('name');
        let isChecked = $input.prop('checked');
        let isBoolean = $input.attr('data-checkbox-type') == 'boolean' ? true : false;
        let isRequired = $input.prop('required');
        if (checkBoxName in checkboxes) {
            if (isChecked) {
                let newValue = $input.val();
                let oldsValues = checkboxes[checkBoxName]['value'];
                if (oldsValues) {
                    oldsValues += "," + newValue;
                } else {
                    oldsValues = newValue;
                }
                checkboxes[checkBoxName]['value'] = oldsValues;
            }
        } else {
            checkboxes[checkBoxName] = {};
            if (isChecked) {
                checkboxes[checkBoxName]['value'] = $input.val();
            } else {
                checkboxes[checkBoxName]['value'] = null;
            }
            checkboxes[checkBoxName]['validation'] = '';
            checkboxes[checkBoxName]['isBoolean'] = false;
            if (isRequired) {
                checkboxes[checkBoxName]['validation'] = 'required';
            }
            if (isBoolean) {
                checkboxes[checkBoxName]['isBoolean'] = true;
            }
        }
    } else if ($input.val()) {
        value = $input.val();
    } else if ($input.attr('data-default')) {
        value = $input.attr('data-default');
    } else {
        value = '';
    }
    data[$input.attr('name')] = {
        'value': value,
        'validation': trimChar(validation, '|')
    };

    return data;
}

$('.c-form .send-btn').click(function(e) {
    e.preventDefault();
    var errorsClasses = document.getElementsByClassName("text-red");
    for (var i = 0; i < errorsClasses.length; i++) {
        errorsClasses[i].innerHTML = '';
    }

    if (!$(this).hasClass('disabled')) {

        $(this).addClass('disabled');
        $('form .text-red').html("");

        if (!$('.response-msg-error.hidden').length) {
            $('.response-msg-error').addClass('hidden');
        }

        let data = {
            'g-recaptcha-response': { 'value': $('.g-recaptcha-response').val(), 'validation': 'required|captcha' },
        };
        const checkboxes = {};

        $('#article-newsletter-form input:not([name^="multiple"]), #article-newsletter-form select:not([name^="multiple"])').each(
            function(index, input) {
                if ($(input).is(':disabled') || ($(input).is(':hidden') && $(input).attr('type') != 'hidden')) return;
                data = {...data, ...formatData(input, checkboxes) };
            }
        );

        var serializedMultiple = $('#article-newsletter-form .multiple-options-field:not(.hidden) input[name^="multiple"], #article-newsletter-form .multiple-options-field:not(.hidden) select[name^="multiple"], #article-newsletter-form .multiple-options-field:not(.hidden) textarea[name^="multiple"]').serializeControls();

        data = {...data, ...serializedMultiple };

        for (var key of Object.keys(checkboxes)) {
            let answer = checkboxes[key]['value'];
            if (checkboxes[key]['isBoolean']) {
                answer = (answer == null) ? false :
                    Object.prototype.toString.call(answer) === "[object String]" ? answer.trim() : answer;
            }
            data[key] = {
                'value': answer,
                'validation': checkboxes[key]['validation']
            }
        }

        $('textarea:not([name^="multiple"])').each(function() {
            var validation = '';
            var isRequired = $(this).attr('required');
            if (isRequired) {
                validation = 'required';
            }
            data[$(this).attr('name')] = { 'value': $(this).val(), 'validation': validation };
        });

        if ($('#due_date').length) {
            data.due_date_active = null;

            if ($('#is_expecting').is(":checked")) {
                data.due_date_active = true;

                if ($('input[name="due_date_day"]').val() != "" && $('input[name="due_date_month"]').val() != "" && $('input[name="due_date_year"]').val() != "") {
                    data.due_date_day = $('input[name="due_date_day"]').val();
                    data.due_date_month = $('input[name="due_date_month"]').val();
                    data.due_date_year = $('input[name="due_date_year"]').val();
                }
            }

            if ($('#is_expecting_not').is(":checked")) {
                data.due_date_active = false;
            }
        }

        if ($('#dob_date').length) {
            data.dob_date_active = null;

            if ($('#has_children').is(":checked")) {
                data.dob_date_active = true;

                let dates = [];

                $('.child-day').each(function(index, value) {
                    dates.push({
                        day: $(value).val(),
                        month: $('.child-month').eq(index).val(),
                        year: $('.child-year').eq(index).val(),
                    });
                });

                data.dob_date_array = JSON.stringify(dates);

                if (dates.length <= 0) {
                    data.dob_date_array = null;
                }

            }

            if ($('#has_children_not').is(":checked")) {
                data.dob_date_active = false;
            }
        }

        if ($('#agree').length) {
            data.agree_enabled = true;
            if ($('#agree').is(":checked")) {
                data.agree = $('#agree').is(":checked")
            }
        }

        if ($('#agree_2').length) {
            data.agree_2_enabled = true;
            if ($('#agree_2').is(":checked")) {
                data.agree_2 = $('#agree_2').is(":checked");
            }
        }
        
        data = removeBlankFields(data);

        $('.c-form .loader').removeClass('hidden');
        axios
            .post(`/api/article/signup`, data)
            .then((dataAux) => {
                $('.c-form .send-btn').removeClass('disabled');
                $('.c-form .loader').addClass('hidden');
                let response = JSON.parse(dataAux.request.response);

                if (response.success && 'redirect' in response) {
                    window.location.href = response.redirect;
                } else if (response.success) {
                    $('.c-form .send-btn').addClass('hidden');
                    $('.response-msg-success').removeClass('hidden');
                }
            })
            .catch((error) => {
                let data = error.response.data;
                $('.c-form .send-btn').removeClass('disabled');
                $('.c-form .loader').addClass('hidden');
                let status = error.response.status;
                if (status === 500) {
                    $('.response-msg-error').removeClass('hidden');
                } else if (typeof data !== 'undefined' && Object.keys(data).length > 0) {
                    Object.keys(data).forEach(function(value) {
                        var val = value.replace(/(\[|]\[)/g, '-').replace(/]/g, '');

                        $('.' + val + '-error').html(data[value][0]);
                    });
                } else {
                    $('.response-msg-error').removeClass('hidden');
                }
                grecaptcha.reset();
            });
    }
});

window.onload = scrollSignup;

$(window).bind('hashchange', function() {
    scrollSignup();
});

$('a').on('click', function() {
    if ($(this).attr('href').indexOf('#signup') > -1) {
        scrollSignup();
    }
});

['ww_articles_loaded', 'ww_vimeo_loaded'].forEach(function(ev) {
    document.addEventListener(ev, function() {
        setTimeout(function() {
            scrollSignup();
        }, 500);
    });
});

function scrollSignup(){
    if(document.querySelector('.js-signup-form-scroll')) {
        const id = document.querySelector('.js-signup-form-scroll').id;
        let hash_signup = window.location.hash === '#' + id;

        if (hash_signup) {
            let el_signup = $('.js-signup-form-scroll');

            if (el_signup.length > 0) {
                $('html,body').animate({
                    scrollTop: el_signup.offset().top - 150
                }, 'slow');
            }
        }
    }
}

$(document).ready(function() {
	document.getElementById("g-recaptcha-response")?.remove();
    $('[data-required-field]').each(
        function(index) {
            if ($(this).data('required-field')) {
                var dependency = {
                    'field': $(this).data('name'),
                    'value': (typeof $(this).data('required-value') == 'boolean') ? $(this).data('required-value').toString() : $(this).data('required-value')
                };
                if ($(this).data('required-field') in dependenciesFields) {
                    var field = dependenciesFields[$(this).data('required-field')];
                    field.push(dependency);
                    dependenciesFields[$(this).data('required-field')] = field;
                } else {
                    dependenciesFields[$(this).data('required-field')] = [dependency];
                }
            }
        }
    );

    $('.multiple-options-field').each(function(index, field) {
        var $subFields = $(field).find('[name]');
        var name = $(field).data('name');

        $(field).append('<input type="hidden" name="multiple[' + name + '][external_key]" value="' + $(field).data('external-key') + '" />');

        $subFields.each(function(i, subField) {
            var subFieldName = $(subField).attr('name');

            $(subField).attr('name', 'multiple[' + name + '][values][' + index + '][' + subFieldName + ']');
            $(subField).nextAll('.' + subFieldName + '-error').first()
                .removeClass(subFieldName + '-error')
                .addClass('multiple-' + name + '-values-' + index + '-' + subFieldName + '-error');
        });
    });

    $('.multiple-options-field .add-button').on('click', function(e) {
        e.preventDefault();

        var $wrapper = $(this).parents('.multiple-options-field');
        var $group = $wrapper.find('.multiple-options-field-group').last();
        var $clone = $group.clone();

        $clone.find('.remove-button').remove();
        $clone.prepend('<button class="remove-button">-</button>');
        $clone.find('textarea, select, input').each(function(index, field) {
            var groupIndex = $group.index() + 1;
            var name = $(field).attr('name');
            var newName = name.replace(/(\w+\[\w+]\[)(\d+)(]\[\w+])/, '$1' + groupIndex + '$3');

            $(field).attr('name', newName);
            $(field).val('');

            $(field).nextAll('.' + name.replace(/(\[|]\[)/g, '-').replace(/]/g, '') + '-error').first()
                .removeClass(name.replace(/(\[|]\[)/g, '-').replace(/]/g, '') + '-error')
                .addClass(newName.replace(/(\[|]\[)/g, '-').replace(/]/g, '') + '-error');
        });

        $clone.find('.remove-button').on('click', function(e) {
            e.preventDefault();

            var $parent = $(this).parents('.multiple-options-field-group');

            $parent.remove();
        });

        $group.after($clone);
    });
});

function showRequiredQuestions(field, type) {
    if (field in dependenciesFields) {
        let dependencyField = null;
        let selectedValue = null;
        if (type === 'radio') {
            dependencyField = $("input[name='" + field + "']:checked");
            selectedValue = $("input[name='" + field + "']:checked").val();
        } else if (type === 'dropdown') {
            dependencyField = $("select[name='" + field + "']");
            selectedValue = $("select[name='" + field + "'] option").filter(":selected").val();
        }
        dependenciesFields[field].forEach(function(dependency, key) {
            var field = $("[data-name='" + dependency.field + "']");
            if (dependency.value == selectedValue && dependencyField.is(':visible')) {
                if(field.data('is-container') == true) {
                    field.show();
                } else if (field.data('true-type') == 'textarea') {
                    field.parent('.dynamic-form-column').removeClass('hidden');
                    const textarea = $("<textarea></textarea>").attr({
                        id: field.attr('id'),
                        name: field.attr('data-name'),
                        'data-name': field.attr('data-name'),
                        value: field.val(),
                        placeholder: field.attr('placeholder'),
                        required: !!field.attr('required'),
                        class: field.attr('class'),
                    });
                    field.after(textarea).remove();
                } else if (field.data('true-type') == 'multiple') {
                    field.removeClass('hidden');
                } else {
                    field.parent('.dynamic-form-column').removeClass('hidden');
                    field.prop('type', field.data('true-type'));
                    field.prop('name', field.data('name'));
                    field.removeAttr('disabled');
                }
                // Trigger onchange event on shown item to cascade change
                field.trigger('change');
                field.find('[onchange]').trigger('change');
            } else {
                if(field.data('is-container') == true) {
                    field.hide();
                } else if (field.is('textarea')) {
                    field.parent('.dynamic-form-column').addClass('hidden');
                    const hidden = $('<input></input>').attr({
                        type: 'hidden',
                        'data-true-type': 'textarea',
                        id: field.attr('id'),
                        'data-name': field.attr('data-name'),
                        value: field.val(),
                        placeholder: field.attr('placeholder'),
                        required: !!field.attr('required'),
                        class: field.attr('class'),
                    })
                    field.after(hidden).remove();
                } else if (field.data('true-type') == 'multiple') {
                    field.addClass('hidden');
                } else {
                    field.parent('.dynamic-form-column').addClass('hidden');
                    field.prop('type', 'hidden');
                    field.prop('data-name', field.prop('name'));
                    field.removeProp('name');
                    field.attr('disabled', 'disabled');
                }
                // Trigger onchange event on hidden item to cascade change
                field.trigger('change');
                field.find('[onchange]').trigger('change');
            }
        });
    }
}


function removeBlankFields(data) {
    let obj = {}

    for (const key in data) {
        var element = data[key]
        console.log(element);
        if(element.value != null && element.value != undefined) {
            obj[key] = element
        }
    }


    return obj
}

$("#signup .dynamic-form-column .inputText").attr("aria-label","label");
$("#signup textarea").attr("aria-label","label");

window.showRequiredQuestions = showRequiredQuestions;
window.removeBlankFields = removeBlankFields;
