<template>
    <transition name="modal">
        <div class="modal-mask search-modal">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <button
                        class="close-btn"
                        type="button"
                        @click="$emit('close')"
                    >
                        close
                    </button>
                    <search-form
                        :article-title="articleTitle"
                        :product-title="productTitle"
                        :popup-title="popupTitle"
                        :popup-placeholder="popupPlaceholder"
                        :search-slug="searchSlug"
                        :faqs="faqsAux"
                    />
                    <!-- <input
                        v-if="false"
                        name="search"
                        type="text"
                        class="w-1/2 bg-grey-light rounded-lg p-3 text-grey-darker"
                        placeholder="Can't find what you're after? Search Here!"
                    /> -->

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import SearchForm from './Form';

export default {
    components: { SearchForm },
    props: {
        articleTitle: {
            type: String,
            required: true,
        },
        productTitle: {
            type: String,
            required: true,
        },
        popupTitle: {
            type: String,
            required: true,
        },
        popupPlaceholder: {
            type: String,
            required: true,
        },
        searchSlug: {
            type: String,
            required: true,
        },
        faqs:{
            type: String,
            required: true
        }
    },
    data() {
        return {
            showModal: false,
            faqsAux: []
        };
    },

    mounted(){
        this.faqsAux = JSON.parse(this.faqs);
    },
};
</script>
