<style scoped>

    .search-modal-wrapper{
        overflow: auto;
    }

    .faq-form{
        margin-top:50px;
    }
</style>

<template>
    <div class="search-modal-wrapper">
        <div class="heading">
            <p data-aos="fade-up">
                {{popupTitle}}
            </p>
        </div>
        <div class="search-input-container">
            <input
                v-model="search"
                type="text"
                name="search"
                data-aos="fade-up"
                data-aos-delay="50"
                :placeholder="popupPlaceholder"
                @keyup="fetch"
            />
            <input
                type="submit"
                class="magnifier"
            />
        </div>
        <div class="modal-results">
            <ul v-if="(products != null && products.length > 0)">
                <p class="sub-heading">
                    {{productTitle}}  
                </p>
                <li
                    v-for="result in products"
                    :key="result.id"
                >
                    <a :href="result.route">
                        {{result.name}}
                    </a>
                </li>
            </ul>
            <ul v-if="(articles != null && articles.length > 0)">
                <p class="sub-heading">
                    {{articleTitle}}
                </p>
                <li
                    v-for="result in articles"
                    :key="result.id"
                >
                    <a :href="result.route">
                        {{result.title}}
                    </a>
                </li>
            </ul>
        </div>

        <faq-stack class="faq-form" :faqs="faqs"/>
    </div>
</template>

<script>
import axios from 'axios';
import FaqStack from '../faqs/FaqStack';

export default {
    components: {FaqStack},
    props: {
        articleTitle: {
            type: String,
            required: true,
        },
        productTitle: {
            type: String,
            required: true,
        },
        popupTitle: {
            type: String,
            required: true,
        },
        popupPlaceholder: {
            type: String,
            required: true,
        },
        searchSlug: {
            type: String,
            required: true,
        },
        faqs:{
            type: Array,
            required: true
        }
    },

    data() {
        return {
            search: '',
            products: [],
            articles: [],
        };
    },

    watch: {
        search() {
            this.fetch();
        },
    },

    methods: {
        fetch() {
            axios.get(`/api/site/${window.waterwipes.site.id}/search`, { params: { search: this.search } })
                .then(({ data }) => {
                    this.products = data.products;
                    this.articles = data.articles;
                })
                .catch(() => {});
        },
    },
};
</script>
