$(document).ready(()=>{
    let forms = $('form');
	for(let i = 0 ; i< forms.length ; i++){
		let token_input = $(forms[i]).find("input[name=_token]");
		//Manao appel ajax jquery makany amin'ny /api/token maka token d mametraka input
		$.ajax("/api/token",{
			accepts:'application/json',
			success:function(res){
				if(token_input.length==0) {
					// console.log("type")
					const node = document.createElement(`input`);
					$(node).attr("type","hidden");
					$(node).attr("name","_token");
					$(node).attr("value",res.token);
					forms[i].appendChild(node);
				}else{
					$(token_input[0]).attr("value",res.token);
				}
			},
			error:function(res){

			}
			});

	}
})
