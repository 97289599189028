import Vue from 'vue';
import SearchModal from '@components/search/SearchModal';

const app = new Vue({
    el: '#init-search-mobile',

    components: { SearchModal },

    data() {
        return {
            showModal: false,
        };
    },
});

export default app;
